import "../scss/index.scss";

// eslint-disable-next-line
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

console.log(`OAC ${window.bridge.VERSION}`);

if (process.env.NODE_ENV !== "development") {
  try {
    Sentry.init({
      dsn: window.bridge.SENTRY_DSN,
      release: window.bridge.VERSION,
      environment: window.bridge.ENV,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.1,
      allowUrls: "ansor",
    });
  } catch (error) {
    console.log("Error setting up Sentry");
  }
}

// --------------------------------------------------------------------- Burger / Menu

const burgerEl = document.getElementsByClassName("Burger")[0];
const menuEl = document.getElementsByClassName("Menu")[0];

if (burgerEl && menuEl) {
  burgerEl.addEventListener("click", (e) => {
    e.preventDefault();
    menuEl.classList.toggle("active");
  });
}

const closeButtonEl = document.querySelector(".Menu > .CloseButton");

if (closeButtonEl && menuEl) {
  closeButtonEl.addEventListener("click", (e) => {
    e.preventDefault();
    menuEl.classList.toggle("active");
  });
}

// --------------------------------------------------------------------- ChallengesPromo

const challengesPromoEl = document.getElementsByClassName(
  "ChallengesPromo-carouselContainer"
)[0];

if (challengesPromoEl) {
  let currChallengesPromo = 0;

  const totalChallengesPromo = Number(
    getComputedStyle(challengesPromoEl).getPropertyValue("--item-count")
  );

  const challengesPromoNextEl = document.getElementsByClassName(
    "ChallengesPromo-next"
  )[0];

  challengesPromoNextEl.addEventListener("click", (e) => {
    e.preventDefault();
    currChallengesPromo++;
    if (currChallengesPromo === totalChallengesPromo) currChallengesPromo = 0;
    challengesPromoEl.style.setProperty("--curr-item", currChallengesPromo);
  });

  const challengesPromoPrevEl = document.getElementsByClassName(
    "ChallengesPromo-prev"
  )[0];

  challengesPromoPrevEl.addEventListener("click", (e) => {
    e.preventDefault();
    currChallengesPromo--;
    if (currChallengesPromo < 0) currChallengesPromo = totalChallengesPromo - 1;
    challengesPromoEl.style.setProperty("--curr-item", currChallengesPromo);
  });
}

// --------------------------------------------------------------------- Map

const firstScriptTag = window.document.getElementsByTagName("script")[0];
const tag = window.document.createElement("script");
const url = "https://maps.googleapis.com/maps/api/js";
const key = window.bridge.GOOGLE_MAPS_API_KEY;
const src = url + "?key=" + key + "&callback=handleGoogleAPIReady";
tag.src = src;
tag.async = true;
tag.defer = true;

firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

window.handleGoogleAPIReady = function () {
  const mapEl = document.getElementsByClassName("PageHeader-map")[0];
  if (mapEl) {
    const latlng = new window.google.maps.LatLng(
      mapEl.dataset.lat,
      mapEl.dataset.lng
    );

    const map = new window.google.maps.Map(mapEl, {
      zoom: 15,
      center: latlng,
      disableScroll: true,
    });

    new window.google.maps.Marker({
      position: latlng,
      map: map,
    });
  }
};

// --------------------------------------------------------------------- Submission form

const submissionContentEl =
  document.getElementsByClassName("Submission-content")[0];
const submissionButtonEl =
  document.getElementsByClassName("Submission-button")[0];

if (submissionButtonEl) {
  submissionContentEl.addEventListener("click", (e) => {
    e.preventDefault();
    if (e.target.classList.contains("Submission-button")) {
      const submissionFormEl =
        document.getElementsByClassName("Submission-form")[0];
      const formData = new FormData(submissionFormEl);
      fetch("", {
        method: "POST",
        body: formData,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((response) => response.text())
        .then((text) => {
          submissionContentEl.innerHTML = text;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  });
}

// --------------------------------------------------------------------- Connect Modal

const LeaderBoardItemEls = document.querySelectorAll(".LeaderBoard-item");
const connectModalWrapperEl = document.querySelector(".ConnectModal-wrapper");
const connectModalCloseButtonEl = document.querySelector(
  ".ConnectModal-closeButton"
);
const connectModalEl = document.querySelector(".ConnectModal-inner");
const connectModalOverlayEl = document.querySelector(".ConnectModal-overlay");
const rootPageEl = document.querySelector("body");

function closeModal() {
  connectModalWrapperEl.classList.remove("show");
  rootPageEl.classList.remove("ConnectModal-open");
  connectModalEl.innerHTML = "";
  return;
}

if (LeaderBoardItemEls) {
  LeaderBoardItemEls.forEach((userEl) => {
    userEl.addEventListener("click", () => {
      connectModalWrapperEl.classList.add("show");
      rootPageEl.classList.add("ConnectModal-open");
      const id = userEl.dataset.id;
      fetch(`/accounts/modal/${id}/`, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          connectModalEl.innerHTML = text;
        });
    });
  });

  if (connectModalCloseButtonEl) {
    connectModalCloseButtonEl.addEventListener("click", closeModal);
  }

  if (connectModalOverlayEl) {
    connectModalOverlayEl.addEventListener("click", closeModal);
  }
}

// --------------------------------------------------------------------- CoachingTypes

function addCoachingTypeHanders(root) {
  const coachingTypeEls = root.querySelectorAll(
    ".CoachingType-button, .SubCoachingType-button"
  );
  coachingTypeEls.forEach((coachingTypeEls) => {
    coachingTypeEls.addEventListener("click", handleCoachingTypeClick);
  });
}

function handleCoachingTypeClick(e) {
  const el = e.target;
  e.preventDefault();
  const hiddenInput = document.getElementById("CoachingTypes-hiddenInput");
  let allValues = JSON.parse(hiddenInput.value || "[]");
  const clickedValue = el.innerText;
  el.classList.toggle("active");
  if (el.classList.contains("active")) {
    allValues.push(clickedValue);
    if (el.classList.contains("CoachingType-button")) {
      const subCoachingTypes = document.querySelectorAll(
        ".SubCoachingType-panel"
      );
      const index = Array.from(el.parentNode.children).indexOf(el);
      if (subCoachingTypes[index]) {
        subCoachingTypes[index].classList.add("active");
        const subCoachingTypeButtons = subCoachingTypes[index].querySelectorAll(
          ".SubCoachingType-button"
        );
        subCoachingTypeButtons.forEach((el) => {
          el.removeAttribute("disabled");
        });
      }
    }
  } else {
    allValues = allValues.filter((value) => {
      return value !== clickedValue;
    });
    if (el.classList.contains("CoachingType-button")) {
      // Deactivate sub coaching type panel
      const subCoachingTypes = document.querySelectorAll(
        ".SubCoachingType-panel"
      );
      const index = Array.from(el.parentNode.children).indexOf(el);
      if (subCoachingTypes[index]) {
        subCoachingTypes[index].classList.remove("active");
        // Remove sub coaching types from hidden input value,
        // and activate states from buttons
        const subCoachingTypeButtons = subCoachingTypes[index].querySelectorAll(
          ".SubCoachingType-button"
        );
        const valuesToRemove = Array.from(subCoachingTypeButtons).map((el) => {
          el.classList.remove("active");
          el.setAttribute("disabled", true);
          return el.innerText;
        });
        allValues = allValues.filter((value) => {
          return !valuesToRemove.includes(value);
        });
      }
    }
  }
  hiddenInput.setAttribute("value", JSON.stringify(allValues));
}

// --------------------------------------------------------------------- Inputs

function handleInput(el) {
  const fieldEl = el.target.closest(".Field");
  if (fieldEl) {
    const errorlistEls = fieldEl.querySelectorAll(".errorlist");
    errorlistEls.forEach((errorlistEl) => {
      errorlistEl.remove();
    });
  }
}

function addInputHandlers(root) {
  const inputEls = root.querySelectorAll(".Input");

  inputEls.forEach((inputEl) => {
    inputEl.addEventListener("focus", handleInput);
  });
}
// --------------------------------------------------------------------- Dropdowns

function handleDropdown(e) {
  const dropdownEl = e.target.closest(".Dropdown");
  const optionText = e.target.options[e.target.selectedIndex].text;
  dropdownEl.firstElementChild.innerText = optionText;
}

function addDropdownHandlers(root) {
  const dropdownEls = root.querySelectorAll(".Dropdown");

  dropdownEls.forEach((dropdownEl) => {
    dropdownEl.addEventListener("change", handleDropdown);
  });
}

// --------------------------------------------------------------------- HTMX

addInputHandlers(document);
addCoachingTypeHanders(document);
addDropdownHandlers(document);

document.body.addEventListener("htmx:beforeRequest", function (evt) {
  evt.target.setAttribute("request-active", "");
  evt.target.setAttribute("timeout-active", "");
  setTimeout(() => {
    evt.target.removeAttribute("timeout-active");
  }, 500);
});

document.body.addEventListener("htmx:afterOnLoad", function (evt) {
  evt.target.removeAttribute("request-active");
  addInputHandlers(evt.detail.target);
  addCoachingTypeHanders(evt.detail.target);
  addDropdownHandlers(evt.detail.target);
});
